// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
import './_slider'; // eslint-disable-line
import './_ajax'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
// import 'slick-carousel';
// import 'jquery-match-height';
// import objectFitImages from 'object-fit-images';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Init foundation
 */
$(document).foundation();
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
// $(window).on('beforeunload', function (e) {
//   // e.preventDefault;
//   //
//   // alert('tstes');
//   // return 'Are you sure you want to close this tab?';
//   let confirmationMessage = 'o/';
//   (e || window.event).returnValue = confirmationMessage; //Gecko + IE
//   return confirmationMessage;
// });
// const interval = 5000;
// // Set the interval function
// const intervalFunction = () => {
//   // Check if the user is on the home page
//   if (window.location.pathname === '/') {
//     // Display the popup
//     console.log('add');
//     $('.popup-menu').show().addClass('is-active');
//   }
// };
$(document).on('ready', function () {
  $('.tribe-events-c-nav__today').text('Back To This Month');
  /**
   * Make elements equal height
   */
  // $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  // if ($('.of-cover').length) {
  //   objectFitImages('.of-cover');
  // }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $('.menu-icon').on('click', function () {
    $(this).toggleClass('is-active');
    $('.top-bar').toggleClass('is-active').slideToggle().css('display', 'flex');
    $('body').toggleClass('mobile-menu-active');
  });
  // $(window)
  //   .on('toggled.zf.responsiveToggle', function () {
  //     $('.menu-icon').toggleClass('is-active');
  //     $('.top-bar').toggleClass('is-active');
  //   })
  //   .on('changed.zf.mediaquery', function () {
  //     $('.menu-icon').removeClass('is-active');
  //   });

  /**
   * Close responsive menu on orientation change
   */
  // $(window).on('orientationchange', function () {
  //   setTimeout(function () {
  //     if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
  //       $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
  //     }
  //   }, 200);
  // });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let headerHeight = $('.header').innerHeight();
$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(window).scrollTop() > 1) {
    $('.header').addClass('sticky-header');
    if (window.innerWidth > 1024) {
      $('body').css('margin-top', headerHeight + 'px');
    }
  } else {
    $('.header').removeClass('sticky-header');
    if (window.innerWidth > 1024) {
      $('body').css('margin-top', 'initial');
    }
  }
});
