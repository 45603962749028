/* global ajax_object */
function worshipLoad(showPosts, paged, viewAll = false) {
  const data = {
    action: 'worship_load',
    nonce: ajax_object.nonce,
    showPosts: showPosts,
    paged: paged,
    viewAll: viewAll,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      if ($('.sermon__posts-wrapper').length) {
        $('.sermon__posts-wrapper').html(response.html);
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

function eventsLoad(offset) {
  const data = {
    action: 'ajax_events_load',
    nonce: ajax_object.nonce,
    offset: offset,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      if ($('.events-query__container').length) {
        $('.events-query__container').append(response.html);
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

function galleryPagination(key, show) {
  const data = {
    action: 'ajax_gallery_pagination',
    nonce: ajax_object.nonce,
    key: key,
    show: show,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      if ($('.section-gallery__container').length) {
        $('.section-gallery__container').html(response.html);
        $('html, body').animate(
          {
            scrollTop: $('.section-gallery').offset().top,
          },
          1000
        );
      }
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}

function paginationBtnsEvent(el) {
  let show = $(el).data('show'),
    // amount = $(el).data('amount'),
    prev = $(el).find('.previous'),
    next = $(el).find('.next');
  prev.hide();
  $(el)
    .find('.paged')
    .on('click', function (e) {
      e.preventDefault();
      if (!$(this).hasClass('current')) {
        prev.show();
      }
      if ($(this).hasClass('the-last')) {
        next.hide();
      } else {
        next.show();
      }
      if ($(this).hasClass('the-first')) {
        prev.hide();
      }
      $(el).find('.paged').removeClass('current');
      $(this).addClass('current');
      let currentKey = $(this).data('key'),
        key = $(el).find('.current').data('key');
      next.attr('data-key', key + show);
      prev.attr('data-key', key - show);
      galleryPagination(currentKey, show);
    });
  prev.on('click', function (e) {
    e.preventDefault();
    next.show();
    let currentKey = $(el).find('.current').prev().data('key'),
      prevBtn = $(el).find('.current').prev('.paged');
    $(el).find('.current').removeClass('current');
    if ($(prevBtn).hasClass('the-first')) {
      $(this).hide();
    } else {
      $(prevBtn).addClass('current');
    }
    galleryPagination(currentKey, show);
  });
  next.on('click', function (e) {
    e.preventDefault();
    prev.show();
    let currentKey = $(el).find('.current').next().data('key'),
      nextBtn = $(el).find('.current').next('.paged');
    $(el).find('.current').removeClass('current');
    if ($(nextBtn).hasClass('the-last')) {
      $(this).hide();
    } else {
      $(nextBtn).addClass('current');
    }
    galleryPagination(currentKey, show);
  });
}
$(document).on('ready', function () {
  if ($('.gallery-pagination').length) {
    paginationBtnsEvent($('.gallery-pagination'));
  }
  if ($('.view-all--warship').length) {
    $('.view-all--warship').on('click', function (e) {
      // let offset = $(this).data('offset');
      e.preventDefault();
      worshipLoad('all', '', true);
      $('.pagination-worships').hide();
      $(this).hide();
    });
  }
  if ($('.pagination-worships').length) {
    let offset = $('.pagination-worships').data('offset'),
      amount = $('.pagination-worships').data('amount');
    for (let i = 0; i < Math.ceil(amount / offset); i++) {
      if (i < 9) {
        if (i === 0) {
          $('.pagination-worships').append(
            `<a href='#' class="worship-pagination-btn is-active" data-paged='${
              i + 1
            }'>0${i + 1}</a>`
          );
        } else {
          $('.pagination-worships').append(
            `<a href='#' class="worship-pagination-btn" data-paged='${
              i + 1
            }'>0${i + 1}</a>`
          );
        }
      } else {
        $('.pagination-worships').append(
          `<a href='#' class="worship-pagination-btn" data-paged='${i + 1}'>${
            i + 1
          }</a>`
        );
      }
    }
  }

  let showPosts = $($('.worship-pagination-btn')).parent().data('offset');
  if ($('.worship-pagination-btn').length) {
    $('.worship-pagination-btn').on('click', function (e) {
      e.preventDefault();
      let paged = $(this).data('paged');
      if ($(this).hasClass('is-active')) {
        return;
      }
      $('.worship-pagination-btn').removeClass('is-active');
      $(this).addClass('is-active');
      worshipLoad(showPosts, paged, false);
    });
  }

  if ($('.view-all-events').length) {
    $('.view-all-events').on('click', function (e) {
      e.preventDefault();
      let offset = $(this).data('offset');
      eventsLoad(offset);
      $(this).hide();
    });
  }
});
