import Swiper, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  EffectCoverflow,
  Thumbs,
  // Lazy,
} from 'swiper';

Swiper.use([
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  // Lazy,
]);

$(document).on('ready', function () {
  // Project slider
  const homeSlider = new Swiper('.home-slider', {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    grabCursor: false,
    cssMode: true,
    freeMode: false,
    // navigation: {
    //   nextEl: '.home-button-next',
    //   prevEl: '.home-button-prev',
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
  });
  homeSlider.init();
  // if (window.innerWidth < 1024) {
  //   homeSlider.autoplay.stop();
  // }
});
